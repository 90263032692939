<template>
  <div>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <h3>پلن های شما</h3>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-data-table
            align="center"
            :headers="headers"
            :items="userPlans.data"
            :items-per-page="10"
            class="elevation-5"
            hide-default-footer
        >
          <template v-slot:item.remain_seconds="{item}">
            <span v-if="item.remain_seconds !== null">{{ Math.ceil(item.remain_seconds / 60) }}</span>
            <span v-else>نامحدود</span>
          </template>
          <template v-slot:item.remain_count="{item}">
            <span v-if="item.remain_count !== null">{{ item.remain_count }}</span>
            <span v-else>نامحدود</span>
          </template>
          <template v-slot:item.remain_stream_seconds="{item}">
            <span v-if="item.remain_stream_seconds !== null">{{ item.remain_stream_seconds }}</span>
            <span v-else>نامحدود</span>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12">
        <v-pagination
            color="#161853"
            v-model="userPlans.current_page"
            :length="userPlans.last_page"
            circle
            @input="getUserPlans"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MyPlans",

  data() {
    return {
      userPlans: [],

      headers: [
        {text: 'شناسه', value: 'id', align: 'center'},
        {text: 'پلن', value: 'plan.title', align: 'center'},
        {text: 'اعتبار تا', value: 'persian_expire_at', align: 'center'},
        {text: 'دقایق باقی مانده', value: 'remain_seconds', align: 'center'},
        {text: 'تعداد تصاویر باقی مانده', value: 'remain_count', align: 'center'},
        {text: 'دقایق باقی مانده از استریم', value: 'remain_stream_seconds', align: 'center'},
      ],
    }
  },

  methods: {
    getUserPlans() {
      let page = this.userPlans.current_page ?? 1
      window.axios
          .get('/user/user-plans?page=' + page)
          .then((res) => {
            this.userPlans = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    }
  },

  mounted() {
    this.getUserPlans()
  }
}
</script>

<style scoped>

</style>